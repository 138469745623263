<template>
    <div class="d-flex justify-center pa-md-5 mx-lg-auto">
        <v-card class="d-flex justify-center mb-6" v-if="personal" style="max-width: 600px" elevation="2">
            <v-form class="pa-4 mx-lg-auto" ref="form" style="min-width: 350px" v-model="valid" lazy-validation>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete v-model="servicio.personalCargo" :items="personal"
                            label="Personal a Cargo"></v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field dense v-model="servicio.proyecto" label="Proyecto"
                             />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field dense v-model="servicio.fechaTermino" label="Fecha Termino" />
                    </v-col>
                </v-row>
                <v-container class="grey lighten-2 pa-3 justify-content item-center my-3">
                    <v-row>
                        <v-col cols="12" md="9">
                            <v-text-field v-model="itemActivity" label="Actividad"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-btn @click="addActivity" color="primary">Agregar</v-btn>
                        </v-col>
                    </v-row>

                    <v-list>
                        <v-list-item-group v-if="arrayActivity.length > 0">
                            <v-list-item v-for="(elemento, index) in arrayActivity" :key="index">
                                <v-list-item-content>
                                    <v-list-item-title>{{ elemento.name }}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn @click="deleteActivity(index)" icon>
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list-item-group>
                        <v-list-item v-else>
                            <v-list-item-content>No hay actividades agregados</v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-container>

                <v-btn block :loading="loading" color="success" class="my-4" @click="validate"> Generar
                </v-btn>
            </v-form>
        </v-card>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { mixins } from '@/mixins/mixin.js'
import DefaultForm from '@/utils/defaultForm'
import { formatNumber } from '@/utils/genericUtils'

export default {
    filters: {},
    props: ['lista', 'service'],
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data() {
        return {
            itemActivity: "",
            arrayActivity: [],
            servicio: DefaultForm.formSelectOt(),
            number: '',
            loadingSync: false,
            dataVenta: '',
            dataProducto: '',
            loading: false,
            valid: true,
            vendedoras: '',
            valordelItem: '',
            personalCargo: '',
            vendedoraRules: [(v) => !!v || 'Vendedora is required'],
            personalCargoRules: [(v) => !!v || 'personal is required'],
            servicioRules: [(v) => !!v || 'servicio is required'],
            mainProductRules: [(v) => !!v || 'Producto Principal is required'],
            unidadesRules: [(v) => !!v || 'unidad is required'],
            select: null,
            checkbox: false,
            items: ['IZQUIERDA', 'DERECHA'],
            headers: [
                { text: 'Actions', value: 'action', sortable: false },
                { text: 'Descripción', value: 'description' },
                { text: 'Codigo', value: 'internal_id' },
                { text: 'Marca', value: 'model' },
                { text: 'Unidades', value: 'unidades' }
            ],
            form: {
                vendedora: '',
                observaciones: '',
                personalCargo: '',
                posicion: ''
            }
        }
    },
    watch: {
        productos: {
            deep: true,
            handler(newList) {
                if (newList.length === 0) {
                    this.listaServicios = []
                } else {
                    this.listaServicios = newList
                }
            }
        }
    },
    computed: {
        ...mapState('utilities', ['tipoDocumento', 'tipoComprobante', 'tipoBancoDestino']),
        ...mapState('users', ['personal', 'listaUser']),



    },

    async mounted() {
        await this.$store.dispatch('users/getPersonas')

    },
    methods: {
        addActivity() {
            if (this.itemActivity) {
                this.arrayActivity.push({name:this.itemActivity});
                this.itemActivity = "";
            }
        },
        deleteActivity(index) {
            this.arrayActivity.splice(index, 1);
        },

        isformatNumber(item, name) {
            const value = item[name]
            return formatNumber(value, item.idTipoMoneda)
        },

        async validate() {
            const validacion = await this.$refs.form.validate()
            console.log("validacion", validacion)
            if (validacion) {
                // this.loading = true

                const crearOrdenEquipamiento = await this.$store.dispatch('inventario/postAddOT', {
                    personalCargo: this.servicio.personalCargo,
                    proyecto: this.servicio.proyecto,
                    fechaTermino: this.servicio.fechaTermino,
                    items: this.arrayActivity,
                    status: 1
                    // items: valFinalAccesorios
                })

                console.log('crearOrdenEquipamiento', crearOrdenEquipamiento)
                //console.log('this._productos', this._productos)
                //console.log('valFinalAccesorios', valFinalAccesorios)
                this.servicio = DefaultForm.formSelectOt()
                this.arrayActivity = []

                this.loading = false
                this.servicio = 
                this.$router.push(`/logistica/orden-trabajo/lista`)
            }
        },
    }
}
</script>
